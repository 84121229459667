<template>
  <div>
    <a-button icon="left" @click="back" v-if="step!==2">返回</a-button>
    <div class="gw-80 g-center gmt-40">
      <a-steps :current="step" size="small" class="gmb-60">
        <a-step title="验证身份" />
        <a-step title="设置密码" />
        <a-step title="绑定成功" />
      </a-steps>
      <div class="gw-400p g-center">
        <!-- 第一步 -->
        <a-form-model :model="oldForm" v-if="step==0">
          <a-form-model-item>
            <div class="g-flex gb-eee">
              <div class="gh-40p gw-100p g-flex g-flex-c gbd" style="border-right:0;">
                原手机号
              </div>
              <div class="gh-40p g-flex-1 g-flex g-align-center gbd gpl-20">
                {{userInfo.mobile}}
              </div>
            </div>
            <span>
              手机无法使用？
              <span style="color:#1890ff" class="g-pointer" @click="back">更换验证方式</span>
            </span>
          </a-form-model-item>
          <a-form-model-item>
            <div class="g-flex g-justify-space-between">
              <a-input v-model="oldForm.code" class="gmr-20" placeholder="请输入验证码" size="large" />
              <a-button size="large" v-if="timeNum==0" @click="getCodeToMobile" :disabled="loading">获取验证码</a-button>
              <div class="gw-110p" v-else>{{timeNum}}</div>
            </div>
            <span class="gc-red">{{tipMsg}}</span>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" block size="large" @click="next">下一步</a-button>
          </a-form-model-item>
        </a-form-model>
        <!-- 第二步 -->
        <a-form-model :model="oldForm" v-if="step==1">
          <a-form-model-item>
            <a-input-password addon-before="新的密码" v-model="newForm.password " placeholder="请输入内容" size="large" />
          </a-form-model-item>
          <a-form-model-item>
            <div>
              <a-input-password addon-before="确认密码" v-model="newForm.confirmPassword " placeholder="请输入内容" size="large" />
            </div>
            <span class="gc-red">{{tipMsg}}</span>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" block size="large" @click="next">下一步</a-button>
          </a-form-model-item>
        </a-form-model>
        <!-- 第三步 -->
        <div v-if="step==2" class="gtc">
          <div class="gfs-20 gfw-b gmb-40">设置密码成功！</div>
          <a-button type="primary" size="large" style="width:200px" @click="back">返回首页</a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCodeToMobile, checkCode, updatePassword } from "@/api/login";
export default {
  data() {
    return {
      step: 0,
      oldForm: {
        code: "",
      },
      newForm: {
        password: "",
        confirmPassword: "",
      },
      tipMsg: "",
      timeNum: 0,
      timeId: "",
      loading: false,
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  beforeDestroy() {
    clearInterval(this.timeId);
  },
  methods: {
    //手机发送验证码
    getCodeToMobile() {
      this.loading = true;
      getCodeToMobile(this.userInfo.mobile).then((res) => {
        if (res.code == 200) {
          this.timeNum = 60;
          this.timeId = setInterval(() => {
            this.timeNum--;
            if (this.timeNum == 0) {
              clearInterval(this.timeId);
            }
          }, 1000);
        } else {
          this.tipMsg = res.msg;
        }
        this.loading = false;
      });
    },
    next() {
      if (this.step == 0) {
        if (!this.oldForm.code) {
          this.tipMsg = "请输入验证码";
          return;
        }
        checkCode({
          ...this.oldForm,
          mobile: this.userInfo.mobile,
        }).then((res) => {
          if (res.code == 200) {
            this.step++;
            this.tipMsg = "";
            this.timeNum = 0;
            clearInterval(this.timeId);
          } else {
            this.tipMsg = res.msg;
          }
        });
      } else if (this.step == 1) {
        if (!this.newForm.password) {
          this.tipMsg = "请设置新密码";
          return;
        }
        if (!this.newForm.confirmPassword) {
          this.tipMsg = "请输入确认密码";
          return;
        }
        if (this.newForm.confirmPassword !== this.newForm.password) {
          this.tipMsg = "两次密码不一致";
          return;
        }
        updatePassword({
          ...this.newForm,
          ...this.oldForm,
          type: 1,
        }).then((res) => {
          if (res.code == 200) {
            this.step++;
            this.tipMsg = "";
            this.timeNum = 0;
            clearInterval(this.timeId);
            this.$store.dispatch("user/getUserInfo");
          } else {
            this.tipMsg = res.msg;
          }
        });
      }
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-form-item {
  margin-bottom: 10px;
}
</style>